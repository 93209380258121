import {
  SwiperSection,
  SwiperSectionProps,
  useCaseType,
} from '@softopus-io/rimac-ui-lib';

import {
  FooterQuery,
  FormQuery,
  HeroVideoSectionFragment,
  ImageCardsWithLinkFragment,
  LargeImageSectionFragment,
  NavigationQuery,
  NewsroomFragment,
  SeoFragment,
} from '@/api';
import { Layout } from '@/components/layout/Layout';
import { Partners } from '@/components/partners/Partners';
import { Seo } from '@/components/Seo';
import HeroVideoSection from '@/components/shared/HeroVideoSection';
import LargeImage from '@/components/shared/LargeImage';
import getCdlPublicId from '@/hooks/useCdlPublicId';

type Props = {
  seo?: SeoFragment | null;
  hero?: HeroVideoSectionFragment | null;
  cards?: ImageCardsWithLinkFragment | null;
  largeImageSection?: LargeImageSectionFragment | null;
  newsroom?: NewsroomFragment | null;
  formData: FormQuery;
  footerData: FooterQuery;
  headerData: NavigationQuery;
};

export const Homepage = ({
  seo,
  hero,
  cards,
  largeImageSection,
  newsroom,
  formData,
  footerData,
  headerData,
}: Props) => {
  const cardsProps: SwiperSectionProps = {
    cards: (cards?.imageCardLink || []).map((card) => ({
      image: {
        imgSrc: getCdlPublicId(card?.img_desktop.data?.attributes?.url || ''),
        imgSrcMobile: getCdlPublicId(
          card?.img_mobil?.data?.attributes?.url || undefined,
        ),
        alt: card?.alt || '',
        blurHash: card?.img_desktop.data?.attributes?.blurhash || undefined,
        link: card?.link_path || '',
      },
      title: card?.title || '',
      text: card?.description || '',
      path: card?.link_path || '',
    })),
    nonLazyLoadImageCount: 0,
    useCase: 'technology' as useCaseType,
    anchor: cards?.anchor || undefined,
  };

  return (
    <Layout formData={formData} footerData={footerData} headerData={headerData}>
      <Seo seo={seo} />
      <HeroVideoSection hero={hero} />
      <SwiperSection {...cardsProps} />
      <LargeImage
        largeImageSection={largeImageSection}
        descriptionMaxWidth={760}
      />
      <Partners data={newsroom} />
    </Layout>
  );
};
